import $ from 'jquery';

$(document).ready(function () {
  isoTabWidget();
});

function isoTabWidget() {
  $('#sequential_tab').click(function () {
    $('#standard_tab').removeClass('current_tab');
    $('#standard_bulk_info').removeClass('show_panel');
    $('#sequential_bulk_info').addClass('show_panel');
    $('#sequential_tab').addClass('current_tab');
  });
  $('#standard_tab').click(function () {
    $('#standard_tab').addClass('current_tab');
    $('#standard_bulk_info').addClass('show_panel');
    $('#sequential_bulk_info').removeClass('show_panel');
    $('#sequential_tab').removeClass('current_tab');
  });
}
