// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';
/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
import '../blocks/elementor/card-biospecimen-widget/index.js'; import '../blocks/elementor/card-research-widget/index.js'; import '../blocks/elementor/card-widget/index.js'; import '../blocks/elementor/events-slider-widget/index.js'; import '../blocks/elementor/example-widget/index.js'; import '../blocks/elementor/iso-tab-widget/index.js'; import '../blocks/elementor/list-by-tag-widget/index.js'; import '../blocks/elementor/staff-slider-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }
  if ($('.staff-slider').length) {
    // var staffSlider = new Swiper('.staff-slider .elementor-swiper-container', {
    //   effect: 'fade',
    //   fadeEffect: {
    //     crossFade: true,
    //   },
    // });
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  // three panel tab widget

  var $open_cell_height = $('.tab_content_parent_dd.visible').height();
  $('#tab_button_container_dd').height($open_cell_height);

  $('#technical_specifications').click(function () {
    $('.tab_button_dd').removeClass('visible');
    $('.tab_content_parent_dd').removeClass('visible');
    $(this).addClass('visible');
    $('#technical_specifications_content').addClass('visible');
    var $open_cell_height = $('.tab_content_parent_dd.visible').height();
    $('#tab_button_container_dd').height($open_cell_height);
  });
  $('#certificate_of_analysis').click(function () {
    $('.tab_button_dd').removeClass('visible');
    $('.tab_content_parent_dd').removeClass('visible');
    $(this).addClass('visible');
    $('#certificate_of_analysis_content').addClass('visible');
    var $open_cell_height = $('.tab_content_parent_dd.visible').height();
    $('#tab_button_container_dd').height($open_cell_height);
  });
  $('#resources_tab').click(function () {
    $('.tab_button_dd').removeClass('visible');
    $('.tab_content_parent_dd').removeClass('visible');
    $(this).addClass('visible');
    $('#resources_tab_content').addClass('visible');
    var $open_cell_height = $('.tab_content_parent_dd.visible').height();
    $('#tab_button_container_dd').height($open_cell_height);
  });

  // // open the chat widget after a certain time on site
  // var triggerLength = 1000 * 90; // 90 seconds
  // if (!Cookies.get('chatTrigger')) {
  //   var cookieTime = new Date().getTime();
  //   Cookies.set('chatTrigger', cookieTime, {
  //     domain: 'allcells.com',
  //     secure: true,
  //   });
  // }
  // if (Cookies.get('chatTrigger') && !Cookies.get('chatOpened')) {
  //   var ddInterval = setInterval(function () {
  //     var entryTime = Cookies.get('chatTrigger');
  //     var triggerTime = new Date();
  //     triggerTime = new Date(triggerTime.getTime() - triggerLength);
  //     if (triggerTime.getTime() >= entryTime) {
  //       $('div#chat_unit').addClass('open');
  //       Cookies.set('chatOpened', 1, {
  //         expires: 1,
  //         domain: 'allcells.com',
  //         secure: true,
  //       });
  //       clearInterval(ddInterval);
  //     }
  //   }, 1000);
  // }
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

jQuery(document).ready(function ($) {
  $('#deploy_store').click(function () {
    $('#shop_container').toggleClass('show_store');
    $('.hideable').toggleClass('show_store');
    $('#tab_widget_parent_dd').toggleClass('show_store');
    $('.dd_product_highlight').toggleClass('show_store');
    $('#deploy_store').toggleClass('state_on');
  });
  $('#collapse_store').click(function () {
    $('#shop_container').removeClass('show_store');
    $('.hideable').removeClass('show_store');
    $('#tab_widget_parent_dd').removeClass('show_store');
    $('.dd_product_highlight').removeClass('show_store');
    $('#deploy_store').removeClass('state_on');
  });
  $('#exit_webstore').click(function () {
    $('#shop_container').removeClass('show_store');
    $('.hideable').removeClass('show_store');
    $('#tab_widget_parent_dd').removeClass('show_store');
    $('.dd_product_highlight').removeClass('show_store');
    $('#deploy_store').removeClass('state_on');
  });
  $('#show_form').click(function () {
    $('#form_spacer').css('height', 130 + 'px');
  });
});

$(document).ready(function () {
  $('.search-btn').on('click', function () {
    $('.open_search').toggleClass('search-open');
  });
});
